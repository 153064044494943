import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Martin | Student', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Martin Urueta III',
  subtitle: 'UC Merced Student CSE',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'My name is Martin Urueta III. Im currently a student at the University of California Merced that is working on a Bachelor Degree in Engineering in Computer Science and Engineering. I aim to be a Web Developer / Software Engineer. ',
  paragraphTwo: 'I was born and raised in Southern California, San Diego. In my leisure time, I would play tennis, video games, read mangas / books, and solve mathmatic problems for fun. ',
  paragraphThree: 'The First Semester, I qualifed for Dean\'s list with a GPA of 3.9 and hopefully qualifed for Chancellor\'s list for my first year in college.',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'data.png',
    title: 'SQL Data',
    info: 'This is a project that involves students in my high school putting their favorite music on a form that will send to a SQL Database using Adminer. Then it will display all songs that was saved in the database for people to search their songs, albums, lyrics, and artist. As well as catagorizing them by name, album, lyrics, artist, and Genre. This is mostly used with PHP and MySQL Database',
    info2: 'FINISHED PROJECT',
    url: 'http://foothillertech.com/student/webdesign/2017/02_34/tinker/data/indexData.php',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'DataAPI.png',
    title: 'Steam Stats',
    info: 'Creating a group project that involve with a an Steam API that can track players CS:GO stats and as well as being able to comment on people and private message each other using PHP and MySQL Database.',
    info2: 'SOMEWHAT FINISH PROJECT',
    url: 'http://foothillertech.com/student/globalit/2019/GamerMedia/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'merdev.png',
    title: 'Merdev',
    info: 'This is a group project that is about creating a student stack overflow for CSE Majors to ask questions about anything. This whole project involves with React and Node.js and as well as Google Firebase. I mainly worked on testing the site using Cypress.',
    info2: 'CONINUING PROJECT',
    url: 'https://merdev.kylan.io/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/martin-urueta-iii-5128a3170',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/martinurueta',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
